
export default {
    yellow: "#E3C36F",
    breakpoints: {
        min: 700, 
        mid: 1000, 
        max: 1200
    },

    transition: "0.2s"
}