import styled from "@emotion/styled";
import theme from "../theme";
export default styled.div`
  display: inline-block;
  width: calc(100% - 84px - 24vw);
  position: absolute;
  left: calc(84px + 24vw);
  height: 100vh;
  background: #E5E5E5;
  z-index: 1;
  overflow-y: scroll;
  overflow: scroll;
  

  .closer {
    display: none;
  }
  

  @media screen and (max-width: ${ theme.breakpoints.mid }px) {
      display: none;

      &.active {
        display: block;
        position: absolute;
        z-index: 9;
        top: 0px;
        right: 0;
        left: 0;
        background: #fff;
        bottom: 0;
        min-width: 100%;
        width: 100%;
      }
      

      .closer {
        display: block;
        float: right;
        margin-left: 12px;
        cursor: pointer;
      }
  }


  .white-background {
    background: #fff;
    transition: all 0.2s ease;
  }

  .my-main {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    height: 100vh;
    padding: 3px 18px;
    
    padding-bottom: 100px;

    @media screen and (max-width: ${ theme.breakpoints.mid }px) {
      padding-bottom: 140px; 
      max-width: 100%;
    }
    
    background: #fff;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      padding: 16px 0;
    }

    .my-image {
      display: block;
      height: 90px;
      width: 90px;
      border-radius: 50px;
      margin: 0 auto;
      margin-bottom: 18px;
    }

    .my-card-image {
      max-width: 211px;
      width: 100%;
      display: block;
      margin: 0 auto;
      cursor: pointer;

    }

    .my-name {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: center;
    }

    .my-button {
      max-width: 315px;
      width: 100%;
      height: 50px; 
      padding-top: 13px;
      padding-bottom: 15px;
      text-align: center;
      font-weight: bold;
      color: #916C0D;
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
      background: ${ theme.yellow};

      &.full-width {
        max-width: 100%;
      }
    }

    hr {
      background: #ccc;
      height: 1px;
    }

    .company-title {
      display: inline-block;

     

      .company {
        font-size: 16px;
      }
    }

    .my-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .company-logo {
      float: right;
      display: inline-block;

      .gatsby-image-wrapper {
        width: 44px;
      }
    }

    .my-secondary {
      font-size: 14px;
      line-height: 19px;
      color #666;
    }

    .my-link {
      padding: 10px;
      display: grid;
      grid-template-columns: 40px calc(100% - 40px);
      grid-gap: 12px;

      .gatsby-image-wrapper {
        position: relative;
        width: 38px;
        position: relative;
        top: 3px;
      } 
      
      .link-title {
        font-size: 14px;
        font-weight: bold;
      }

      .link-secondary {
        font-size: 14px;
      }
    }

  }

`;