

function daysFromNow(days = 7) {
  var d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  return d.toUTCString();
}

function decode(val) {
  try {
    return JSON.parse(val);
  } catch (e) {
    return;
  }
}

function encode(val) {
  try {
    return JSON.stringify(val);
  } catch (e) {
    return;
  }
}

function set(key, value, days = 7) {
  if (value === null) days = -1000;
  if(typeof document !== 'undefined'){
    document.cookie = `${key}=${encode(value)}; path=/; expires=${daysFromNow(
      days
    )};`;
  }
}

function get(key, fallback) {
  if(typeof document !== 'undefined'){
    let match = document.cookie.match(new RegExp("(^| )" + key + "=([^;]+)"));
    return match ? decode(match[2]) : fallback;
  }
}

export default {
  set,
  get,
};