import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import styled from "@emotion/styled"
import { TiContacts } from "react-icons/ti"
import Container from "./container"
import VerticalAlign from "./verticalAlign"
import HamburgerMenu from "react-hamburger-menu"
import theme from "../theme"
const Styles = styled.div`
  position: fixed;
  display: inline-block;
  background: #2a2a2a;
  z-index: 10;
  height: 100vh;
  text-align: center;
  width: 84px;

  @media screen and (max-width: ${theme.breakpoints.mid}px) {
    position: fixed;
    width: 100%;
    height: 80px;
    bottom: 0px;
  }

  .my-logo {
    width: 48px;
    display: block;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 12px;
    @media screen and (max-width: ${theme.breakpoints.mid}px) {
      display: none;
    }
  }

  .links {
    @media screen and (max-width: ${theme.breakpoints.mid}px) {
      height: 100%;
    }

    .my-link {
      padding: 8px 0px;
      position: relative;
      font-size: 12px !important;

      @media screen and (max-width: ${theme.breakpoints.mid}px) {
        display: inline-block;
        height: 100%;
        width: 33%;
      }

      &.active {
        color: ${ theme.yellow };
        
        .my-active-element {
          position: absolute;
          width: 6px;
          height: 64px;
          left: 0px;
          border-radius: 0px 20px 20px 0px;
          background: #e3c36f;

          @media screen and (max-width: ${theme.breakpoints.mid}px) {
            width: 100%;
            height: 6px;
            position: absolute;
            bottom: 0;
            border-radius: 20px 20px 0px 0px;
          }
        }
        
        
        svg path {
          fill: ${theme.yellow};
        }

        svg rect {
          stroke: ${theme.yellow};
        }
      }
    }

    svg {
      width: 28px;
      height: 28px;
      position: relative;
      top: 8px;
      margin-bottom: 4px;

    }

    a {
      color: inherit;
    }
  }
`
export default class Header extends React.Component {
  constructor(props) {
    super()
    this.state = {}
    this.state.page = props.page
  }
  componentDidMount() {
    this.setActive()
  }

  setActive() {
    const { page } = this.state;
    console.log(page);
    if(page === 'profiles') return null;
    document
      .querySelectorAll("." + page)[0]
      .classList.toggle("active")
  }

  render() {
    return (
      <Styles>
        <Link to="/account">
          <Logo />
        </Link>

        <div className="links">
          <Link to="/contacts">
            <div className="my-link my-contacts">
              <div className="my-active-element " />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="30"
                viewBox="0 0 29 30"
              >
                <path
                  d="M8.67432 19.7925V21.8157C8.67432 22.0098 8.75141 22.1959 8.88863 22.3331C9.02585 22.4703 9.21196 22.5474 9.40602 22.5474H21.0072C21.2013 22.5474 21.3874 22.4703 21.5246 22.3331C21.6619 22.1959 21.7389 22.0098 21.7389 21.8157V19.7925C21.7351 18.4231 21.1883 17.111 20.2186 16.144C19.2489 15.177 17.9353 14.634 16.5658 14.634H16.3829C17.2119 14.355 17.9148 13.7904 18.366 13.0409C18.8172 12.2915 18.9873 11.4061 18.846 10.5429C18.7046 9.67959 18.261 8.89468 17.5943 8.32831C16.9277 7.76194 16.0814 7.45099 15.2066 7.45099C14.3319 7.45099 13.4856 7.76194 12.8189 8.32831C12.1523 8.89468 11.7087 9.67959 11.5673 10.5429C11.4259 11.4061 11.596 12.2915 12.0472 13.0409C12.4984 13.7904 13.2013 14.355 14.0304 14.634H13.8402C12.472 14.6359 11.1602 15.1798 10.1921 16.1466C9.22392 17.1134 8.67819 18.4243 8.67432 19.7925ZM12.9804 11.1364C12.9804 10.6965 13.1109 10.2664 13.3553 9.90063C13.5997 9.53483 13.9471 9.24972 14.3536 9.08136C14.76 8.913 15.2073 8.86895 15.6388 8.95478C16.0703 9.04061 16.4666 9.25246 16.7777 9.56355C17.0888 9.87464 17.3006 10.271 17.3865 10.7025C17.4723 11.134 17.4282 11.5812 17.2599 11.9877C17.0915 12.3941 16.8064 12.7415 16.4406 12.9859C16.0748 13.2304 15.6447 13.3608 15.2048 13.3608C14.6152 13.3599 14.0499 13.1252 13.633 12.7082C13.216 12.2913 12.9814 11.7261 12.9804 11.1364ZM20.2755 19.7925V21.084H10.1377V19.7925C10.1406 18.8121 10.5319 17.8729 11.2258 17.1803C11.9197 16.4878 12.8598 16.0984 13.8402 16.0974H16.5658C17.5474 16.0964 18.4894 16.485 19.1849 17.1777C19.8804 17.8705 20.2726 18.8109 20.2755 19.7925Z"
                  fill="#999999"
                />
                <path
                  d="M3.86344 11.7073H2.40002V18.2927H3.86344V11.7073Z"
                  fill="#999999"
                />
                <path
                  d="M28.0098 4.39024C28.0098 3.22588 27.5472 2.1092 26.7239 1.28587C25.9006 0.462542 24.7839 0 23.6195 0H4.59515C4.01296 0 3.45463 0.231271 3.04296 0.642936C2.6313 1.0546 2.40002 1.61294 2.40002 2.19512V6.21951H3.86344V2.19512C3.86344 2.00106 3.94053 1.81495 4.07775 1.67773C4.21497 1.54051 4.40109 1.46341 4.59515 1.46341H23.6195C24.3958 1.46341 25.1402 1.77178 25.6891 2.32066C26.238 2.86955 26.5464 3.614 26.5464 4.39024V25.6098C26.5464 26.386 26.238 27.1304 25.6891 27.6793C25.1402 28.2282 24.3958 28.5366 23.6195 28.5366H4.59515C4.40109 28.5366 4.21497 28.4595 4.07775 28.3223C3.94053 28.1851 3.86344 27.9989 3.86344 27.8049V23.7805H2.40002V27.8049C2.40002 28.3871 2.6313 28.9454 3.04296 29.3571C3.45463 29.7687 4.01296 30 4.59515 30H23.6195C24.7839 30 25.9006 29.5375 26.7239 28.7141C27.5472 27.8908 28.0098 26.7741 28.0098 25.6098V4.39024Z"
                  fill="#999999"
                />
                <path
                  d="M0 20.9596C0 21.1536 0.0770902 21.3398 0.214312 21.477C0.351534 21.6142 0.537646 21.6913 0.731707 21.6913H5.67073C5.86479 21.6913 6.05091 21.6142 6.18813 21.477C6.32535 21.3398 6.40244 21.1536 6.40244 20.9596C6.40244 20.7655 6.32535 20.5794 6.18813 20.4422C6.05091 20.305 5.86479 20.2279 5.67073 20.2279H0.731707C0.537646 20.2279 0.351534 20.305 0.214312 20.4422C0.0770902 20.5794 0 20.7655 0 20.9596Z"
                  fill="#999999"
                />
                <path
                  d="M0 9.04021C0 9.23427 0.0770902 9.42038 0.214312 9.55761C0.351534 9.69483 0.537646 9.77192 0.731707 9.77192H5.30488C5.49894 9.77192 5.68505 9.69483 5.82227 9.55761C5.9595 9.42038 6.03659 9.23427 6.03659 9.04021C6.03659 8.84615 5.9595 8.66004 5.82227 8.52282C5.68505 8.38559 5.49894 8.3085 5.30488 8.3085H0.731707C0.537646 8.3085 0.351534 8.38559 0.214312 8.52282C0.0770902 8.66004 0 8.84615 0 9.04021Z"
                  fill="#999999"
                />
              </svg>
              <div>
                Contacts
              </div>
            </div>
          </Link>
          <Link to="/cards">
            <div className="my-link my-cards">
              <div className="my-active-element " />
              <svg width="20" height="30" viewBox="0 0 20 30">
                <rect
                  x="1"
                  y="1"
                  width="17.5652"
                  height="28"
                  rx="2"
                  stroke="#999999"
                  fill="#2a2a2a"
                  strokeWidth="2"
                />
                <path
                  d="M0 21.5217H19.5652V27C19.5652 28.6568 18.2221 30 16.5652 30H3C1.34314 30 0 28.6568 0 27V21.5217Z"
                  fill="#999999"
                />
              </svg>
              <div>
                Cards
              </div>
            </div>
          </Link>

          <Link to="/account">
            <div className="my-link my-account">
              <div className="my-active-element " />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="30"
                viewBox="0 0 26 30"
                fill="none"
              >
                <path
                  d="M0 24.5254V28.5459C0 28.9316 0.153195 29.3014 0.425886 29.5741C0.698576 29.8468 1.06843 30 1.45407 30H24.5083C24.894 30 25.2638 29.8468 25.5365 29.5741C25.8092 29.3014 25.9624 28.9316 25.9624 28.5459V24.5254C25.9547 21.804 24.8682 19.1966 22.9411 17.275C21.014 15.3533 18.4036 14.2742 15.6821 14.2743H15.3186C16.9662 13.7198 18.363 12.5978 19.2596 11.1085C20.1562 9.61923 20.4943 7.85973 20.2134 6.14423C19.9325 4.42873 19.0509 2.86894 17.7261 1.74344C16.4013 0.617932 14.7195 0 12.9812 0C11.2428 0 9.5611 0.617932 8.2363 1.74344C6.9115 2.86894 6.02993 4.42873 5.74901 6.14423C5.4681 7.85973 5.80614 9.61923 6.70277 11.1085C7.5994 12.5978 8.99622 13.7198 10.6438 14.2743H10.2657C7.54678 14.2781 4.94008 15.3589 3.01613 17.2801C1.09218 19.2013 0.0076888 21.8065 0 24.5254ZM8.55719 7.32381C8.55719 6.44954 8.81644 5.59491 9.30216 4.86798C9.78787 4.14106 10.4782 3.57449 11.286 3.23992C12.0937 2.90535 12.9825 2.81782 13.8399 2.98838C14.6974 3.15894 15.485 3.57994 16.1032 4.19814C16.7214 4.81634 17.1424 5.60397 17.313 6.46144C17.4836 7.3189 17.396 8.20769 17.0614 9.01541C16.7269 9.82313 16.1603 10.5135 15.4334 10.9992C14.7065 11.4849 13.8518 11.7442 12.9776 11.7442C11.8058 11.7423 10.6826 11.2759 9.85401 10.4474C9.02545 9.61879 8.55911 8.49557 8.55719 7.32381ZM23.0542 24.5254V27.0919H2.90814V24.5254C2.9139 22.5772 3.69139 20.7106 5.07037 19.3344C6.44936 17.9581 8.31747 17.1843 10.2657 17.1824H15.6821C17.6329 17.1805 19.5047 17.9525 20.8869 19.3292C22.269 20.7059 23.0485 22.5747 23.0542 24.5254Z"
                  fill="#999999"
                />
              </svg>
              <div>
                Account
              </div>
            </div>
          </Link>
        </div>
      </Styles>
    )
  }
}

const Logo = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className="my-logo"
      fluid={data.logo.childImageSharp.fluid}
      alt="Last Card Logo"
    />
  )
}
