import styled from "@emotion/styled";
import theme from "../theme";

export default styled.div`
  display: inline-block;
  position: relative; 
  left: 84px;
  background: #fff;
  width: 24vw;
  height: 100vh;
  padding-bottom: 24px;
  border-right: 1px solid #ccc;
  overflow-y: scroll;
  padding-top: 60px;
  
  &::-webkit-scrollbar {
    display: none;
  }

 

  .my-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    z-index: 10;
    top: 0px;
    left: 0px;
    padding: 16px 18px;
    padding-bottom: 10px;
    width: 100%;
    background: #fff;
    position: fixed;
    height: 60px;
    // border-bottom: 1px solid #ccc;

    @media screen and (min-width: ${ theme.breakpoints.mid }px) {
      width: 24vw;     
      border-right: 1px solid #ccc; 
      left: 84px;
      
    }

  }

  @media screen and (max-width: ${ theme.breakpoints.mid }px) {
    width: 100%;
    left: 0px;
    display: inline-block;
    position: relative;
    

    border-right: none;

    .my-fixed {
      width: 100%;
    }
  }
`;